/* Styles for the about container */
.about-container {
    padding: 20px;
    max-width: 100%;
    margin: auto;
    color: #61dafb;
    background-color: #282c34;
    line-height: 1.6;
}

.about-container h2 {
    margin-top: 20px;
    font-size: 1.75rem;
    color: #61dafb;
}

.about-container p,
.about-container ul {
    margin-bottom: 20px;
    font-size: 1.25rem;
}

.about-container ul {
    list-style-type: disc;
    padding-left: 20px;
}

.about-container ul li {
    margin-bottom: 10px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .about-container {
        padding: 10px;
    }

    .about-container h2 {
        font-size: 1.5rem;
    }

    .about-container p,
    .about-container ul {
        font-size: 1rem;
    }
}

/* Additional adjustments for larger screens */
@media (min-width: 1024px) {
    .about-container h2 {
        font-size: 2rem;
    }

    .about-container p,
    .about-container ul {
        font-size: 1.5rem;
    }
}