/* Reset some default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    font-family: 'Roboto Mono', monospace;
    background-color: #282c34;
    color: #61dafb;
    font-size: 16px;
}

/* Quote Container */
.quote-container {
    padding: 20px;
    max-width: 600px;
    margin: 20px auto;
    background-color: #20232a;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #61dafb;
}

.quote-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #61dafb;
    font-size: 1.75rem;
}

.quote-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quote-container label {
    margin-top: 15px;
    font-size: 1.25rem;
    text-align: left;
    width: 100%;
    color: #61dafb;
}

.quote-container input,
.quote-container textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #61dafb;
    border-radius: 5px;
    background-color: #20232a;
    color: #61dafb;
    font-size: 1rem;
    font-family: 'Roboto Mono', monospace;
}

.quote-container input::placeholder,
.quote-container textarea::placeholder {
    color: #aaa;
}

.quote-container input:focus,
.quote-container textarea:focus {
    outline: none;
    border-color: #9fdfdf;
}

.quote-container button {
    display: block;
    margin: 30px auto;
    background-color: #61dafb;
    color: #20232a;
    border: none;
    padding: 15px 30px;
    cursor: pointer;
    font-family: 'Roboto Mono', monospace;
    transition: background-color 0.3s ease;
    font-size: 1.25rem;
    border-radius: 8px;
}

.quote-container button:hover {
    background-color: #9fdfdf;
}

.quote-container p {
    margin-top: 20px;
    font-size: 1.25rem;
    text-align: center;
    color: #61dafb;
}

/* Responsive adjustments */
@media (max-width: 768px) {

    html,
    body {
        font-size: 14px;
    }

    .quote-container {
        padding: 10px;
        margin: 40px 10px;
        /* Add margin to avoid overlapping with header and footer */
    }

    .quote-container h2 {
        font-size: 1.5rem;
    }

    .quote-container label {
        font-size: 1rem;
    }

    .quote-container input,
    .quote-container textarea {
        font-size: 0.875rem;
    }

    .quote-container button {
        font-size: 1rem;
    }

    .quote-container p {
        font-size: 1rem;
    }
}

@media (min-width: 1024px) {

    html,
    body {
        font-size: 18px;
    }

    .quote-container h2 {
        font-size: 2rem;
    }

    .quote-container label {
        font-size: 1.5rem;
    }

    .quote-container input,
    .quote-container textarea {
        font-size: 1.25rem;
    }

    .quote-container button {
        font-size: 1.5rem;
    }

    .quote-container p {
        font-size: 1.5rem;
    }
}

.confirmation-message {
    color: #61dafb;
    font-size: 1.25rem;
    text-align: center;
    margin-top: 20px;
}

.error-message {
    color: #ff4f4f;
    font-size: 1.25rem;
    text-align: center;
    margin-top: 20px;
}