.terms-container {
    padding: 20px;
    max-width: 100%;
    margin: auto;
    color: #61dafb;
    background-color: #282c34;
}

.terms-section {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #282c34;
    border-radius: 8px;
    background-color: #282c34;
    line-height: 1.6;
}

.terms-section h1,
.terms-section h2 {
    margin-bottom: 15px;
    line-height: 1.2;
}

.terms-section p {
    margin: 0 0 15px 0;
    font-size: 1.25rem;
    line-height: 1.6;
}