/* src/styles/blog.css */
.blog-container {
    padding: 20px;
    max-width: 100%;
    margin: auto;
    color: #61dafb;
    background-color: #282c34;
}

.blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.blog-item {
    border: 1px solid #20232a;
    padding: 10px;
    border-radius: 8px;
    background-color: #20232a;
    text-align: center;
    color: #61dafb;
}

.blog-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}


.blog-item h2 {
    margin: 10px 0;
    color: #61dafb;
    font-size: 1.5rem;
}



.blog-post-container {
    padding: 20px;
    max-width: 1000px;
    margin: auto;
    color: #61dafb;
    background-color: #282c34;
}

.blog-post-image {
    width: 50%;
    height: auto;
    border-radius: 8px;
    padding-left: 0;
    padding-right: 0;
}

.blog-post-container h1 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #61dafb;
    padding-left: 0;
    padding-right: 0;
}

.blog-post-container h2 {
    margin-top: 20px;
    font-size: 1.75rem;
    color: #61dafb;
}

.blog-post-container h3 {
    margin-top: 15px;
    font-size: 1.5rem;
    color: #61dafb;
}

.blog-post-container p,
.blog-post-container ul {
    margin-bottom: 20px;
    font-size: 1.25rem;
}

.blog-post-container ul {
    list-style-type: disc;
    padding-left: 20px;
}

.blog-post-container ul li {
    margin-bottom: 10px;
}

.content-image {
    display: block;
    margin: 20px auto;
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .blog-post-container {
        padding: 10px;
    }

    .blog-post-container h1 {
        font-size: 1.5rem;
        padding-left: 10px;
        padding-right: 10px;
    }

    .blog-post-container h2 {
        font-size: 1.25rem;
    }

    .content-image {
        margin: 10px auto;
    }
}

/* Additional adjustments for larger screens */
@media (min-width: 1024px) {
    .blog-post-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .blog-post-container h1 {
        padding-left: 20px;
        padding-right: 20px;
    }
}