/* Styles for the portfolio container */
.portfolio-container {
    padding: 20px;
    max-width: 100%;
    margin: auto;
    color: #61dafb;
    background-color: #282c34;
    line-height: 1.6;
}

.portfolio-item {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #20232a;
    border-radius: 8px;
    background-color: #20232a;
    color: #61dafb;
}

.portfolio-item h2 {
    margin-bottom: 15px;
    font-size: 1.75rem;
}

.portfolio-item p {
    margin: 0;
    font-size: 1.25rem;
}

.portfolio-item ul {
    list-style-type: disc;
    padding-left: 20px;
}

.portfolio-item ul li {
    margin-bottom: 10px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .portfolio-container {
        padding: 10px;
    }

    .portfolio-item {
        padding: 15px;
    }

    .portfolio-item h2 {
        font-size: 1.5rem;
    }

    .portfolio-item p,
    .portfolio-item ul {
        font-size: 1rem;
    }
}

/* Additional adjustments for larger screens */
@media (min-width: 1024px) {
    .portfolio-item h2 {
        font-size: 2rem;
    }

    .portfolio-item p,
    .portfolio-item ul {
        font-size: 1.5rem;
    }
}