.services-container {
    padding: 20px;
    max-width: 100%;
    margin: auto;
    color: #61dafb;
    background-color: #282c34;
}

.service-section {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #20232a;
    border-radius: 8px;
    background-color: #20232a;
    line-height: 1.6;
    /* Adjusted for better line spacing */
}

.service-section h2 {
    margin-bottom: 15px;
    font-size: 1.75rem;
    line-height: 1.2;
    /* Adjusted for better heading line spacing */
}

.service-section p {
    margin: 0 0 15px 0;
    font-size: 1.25rem;
    line-height: 1.6;
    /* Consistent line spacing with the .service-section */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .services-container {
        padding: 10px;
    }

    .service-section {
        padding: 15px;
    }

    .service-section h2 {
        font-size: 1.5rem;
    }

    .service-section p {
        font-size: 1rem;
    }
}

/* Additional adjustments for larger screens */
@media (min-width: 1024px) {
    .service-section h2 {
        font-size: 2rem;
    }

    .service-section p {
        font-size: 1.5rem;
    }
}


.quote-button {
    display: block;
    margin: 30px auto;
    background-color: #61dafb;
    color: #20232a;
    border: none;
    padding: 15px 30px;
    cursor: pointer;
    font-family: 'Roboto Mono', monospace;
    transition: background-color 0.3s ease;
    font-size: 1.25rem;
    border-radius: 8px;
}

.quote-button:hover {
    background-color: #9fdfdf;
}