/* Styles for the contact form */
.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: auto;
    color: #61dafb;
}

.contact-form label {
    margin-top: 15px;
    font-size: 1.25rem;
    text-align: left;
    width: 100%;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #61dafb;
    border-radius: 5px;
    background-color: #20232a;
    color: #61dafb;
    font-size: 1rem;
    font-family: 'Roboto Mono', monospace;
}

.contact-form input:focus,
.contact-form textarea:focus {
    outline: none;
    border-color: #9fdfdf;
}

.contact-form button {
    display: block;
    margin: 30px auto;
    background-color: #61dafb;
    color: #20232a;
    border: none;
    padding: 15px 30px;
    cursor: pointer;
    font-family: 'Roboto Mono', monospace;
    transition: background-color 0.3s ease;
    font-size: 1.25rem;
    border-radius: 8px;
}

.contact-form button:hover {
    background-color: #9fdfdf;
}

/* Ensure other elements have appropriate spacing and styling */
.contact-form p {
    margin-top: 20px;
    font-size: 1.25rem;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .contact-form {
        padding: 0 10px;
    }
}

@media (min-width: 1024px) {
    .contact-form {
        font-size: 1.5rem;
    }
}