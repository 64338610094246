/* Reset some default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    font-family: 'Roboto Mono', monospace;
    background-color: #282c34;
    color: #61dafb;
    font-size: 16px;
    /* Base font size */
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

header,
footer,
section {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    /* Added padding */
    padding-right: 20px;
    /* Added padding */
}

header {
    background-color: #20232a;
    padding: 20px;
    font-size: 1.25rem;
    /* Increased font size for header */
}

nav {
    background-color: #20232a;
    padding: 10px 0;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin: 0;
    flex-wrap: wrap;
    /* Allows wrapping on smaller screens */
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    color: #61dafb;
    text-decoration: none;
    font-size: 1.25rem;
    /* Increased font size for nav links */
}

footer {
    background-color: #20232a;
    padding: 20px;
    margin-top: auto;
    font-size: 1.25rem;
    /* Increased font size for footer text */
    /* Ensure footer is pushed to the bottom */
}

footer ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
    flex-wrap: wrap;
    /* Allows wrapping on smaller screens */
}

footer ul li {
    margin: 0 15px;
}

footer ul li a {
    color: #61dafb;
    text-decoration: none;
    font-size: 1.25rem;
    /* Increased font size for footer links */
}

button {
    background-color: #61dafb;
    color: #20232a;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-family: 'Roboto Mono', monospace;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    font-size: 1.25rem;
    /* Increased font size for buttons */
}

button:hover {
    background-color: #9fdfdf;
}

main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    /* Increased font size for main content */
    padding-left: 20px;
    /* Added padding */
    padding-right: 20px;
    /* Added padding */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {

    html,
    body {
        font-size: 14px;
        /* Adjust base font size for smaller screens */
    }

    header h1 {
        font-size: 2rem;
        /* Increased font size for smaller screens */
    }

    header p {
        font-size: 1.25rem;
        /* Increased font size for smaller screens */
    }

    nav ul {
        flex-direction: column;
    }

    nav ul li {
        margin: 10px 0;
    }

    footer ul {
        flex-direction: column;
    }

    footer ul li {
        margin: 10px 0;
    }
}

/* Additional adjustments for larger screens */
@media (min-width: 1024px) {

    html,
    body {
        font-size: 18px;
        /* Adjust base font size for larger screens */
    }

    header {
        font-size: 1.5rem;
        /* Increased font size for larger screens */
    }

    nav ul li a {
        font-size: 1.5rem;
        /* Increased font size for larger screens */
    }

    footer {
        font-size: 1.5rem;
        /* Increased font size for larger screens */
    }

    footer ul li a {
        font-size: 1.5rem;
        /* Increased font size for larger screens */
    }

    button {
        font-size: 1.5rem;
        /* Increased font size for larger screens */
    }

    main {
        font-size: 1.5rem;
        /* Increased font size for larger screens */
    }
}

/* src/styles.css */
.typing-cursor {
    font-size: 1.25rem;
    /* Adjust font size as needed */
    color: #61dafb;
}

.main-content {
    padding: 0 20px;
    /* Add padding to the sides for better spacing */
}

/* Add padding to the navbar links */
nav ul li a {
    padding-left: 10px;
    padding-right: 10px;
}

/* src/styles.css */
h1 {
    transition: color 0.3s ease, text-shadow 0.3s ease;
}

nav ul li a {
    transition: color 0.3s ease, text-shadow 0.3s ease;
}


/* Link styles */
a {
    color: #61dafb;
    /* Link color */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}