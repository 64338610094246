/* Use the same colors and styles from your global.css */
.privacy-container {
    padding: 20px;
    max-width: 100%;
    margin: auto;
    background-color: #282c34;
    /* Matches the global background color */
    color: #61dafb;
    /* Matches the global text color */
    line-height: 1.6;
    font-family: 'Roboto Mono', monospace;
    /* Matches the global font */
}

.privacy-container h1,
.privacy-container h2 {
    color: #61dafb;
    /* Matches the global text color */
}

.privacy-container ul {
    list-style: disc;
    margin-left: 20px;
    padding-left: 20px;
}

.privacy-container ul li {
    margin-bottom: 10px;
}

a {
    color: #61dafb;
    /* Matches the global link color */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .privacy-container {
        padding: 10px;
    }

    .privacy-container ul {
        margin-left: 10px;
        padding-left: 10px;
    }
}